import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import CoqIcon from "../../../static/Coq.svg"

const HomeMatelasSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  background: #1b2a56;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;

  @media (max-width: 550px) {
    padding: 0px;
  }
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ProductLinkTitle = styled.span`
  display: none;
`

const ShopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: ${props => (props.padding ? props.padding : "0px 20px")};

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const ContentBox = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 50px 0px;
  padding: 30px 30px;
  max-width: 550px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 550px) {
    padding: 25px;
    margin: 40px 0px 0px;
    position: relative;
  }
`

const TopSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const RightText = styled.div`
  margin-left: 10px;
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #262626;
  font-family: "Museo";
  font-weight: 500;
  font-size: 14px;

  span {
    font-weight: 900;
    font-size: 18px;
    color: ${props => props.theme.saleColor.bgColor};
  }

  sup {
    font-family: "Museo";
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const TopText = styled.div`
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  // border: 1px solid #39c5d9;
  // text-transform: uppercase;
  font-family: "Museo";
  font-weight: 900;
  font-size: 16px;
  border-radius: 10px 0px;
  border-radius: 5px 0px;
  padding: 5px 10px 3px;
  display: flex;
  align-items: center;

  span {
    font-size: 11px;
    margin-left: 5px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const TopBox = styled.div`
  width: 100%;
`

const Title = styled.h1`
  font-size: 36px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  margin: 0px;
`

const SubTitle = styled.h6`
  font-size: 18px;
  line-height: 1;
  font-family: "Museo";
  font-weight: bold;
  color: #262626;
  margin: 0px;
`

const TextWrapper = styled.div`
  width: 100%;
  margin: 15px 0px;
`

const TextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const TickIcon = styled.img`
  width: 100%;
  max-width: 25px;
  margin: 0px 5px 0px 0px;

  @media (max-width: 550px) {
    align-self: flex-start;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 1.1;
  font-family: "Museo";
  font-weight: 500;
  color: #262626;
  margin-bottom: 0px;
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0px;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImageWrapper = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 550px) {
    display: flex;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  align-self: center;

  @media (max-width: 550px) {
    object-fit: contain;
    max-width: 550px;
    width: calc(100% + 40px);
    margin-top: 20px;
    display: block;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props =>
    props.justify ? props.justify : "space-between;"};
  z-index: 1;
  position: relative;

  @media (max-width: 550px) {
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white.base};
  font-size: 18px;
  margin-right: 15px;
  padding: 12px 30px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  border-radius: 25px 0px;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 550px) {
    margin-right: 0px;
    max-width: 100%;
  }
`

const ClockImage = styled.img`
  width: 100%;
  max-width: 20px;
  margin: 0px 0px 0px 5px;
`

const HomeMatelasSection = ({ intl, data }) => {
  return (
    data && (
      <HomeMatelasSectionWrapper>
        {data.button_text && data.button_url && (
          <ProductLink to={data.button_url}>
            <ProductLinkTitle>{data.button_text}</ProductLinkTitle>
          </ProductLink>
        )}
        <ShopContainer>
          <ContentBox>
            {data.button_text && data.button_url && (
              <ProductLink to={data.button_url}>
                <ProductLinkTitle>{data.button_text}</ProductLinkTitle>
              </ProductLink>
            )}
            {/* {data.top_text && (
              <TopText
                dangerouslySetInnerHTML={{
                  __html: data.top_text,
                }}
              />
            )} */}
            {data.top_text && (
              <TopSection>
                <TopText>
                  <ClockImage className="lazyload" data-src={CoqIcon} />
                  Jusqu'au 22 août
                </TopText>
                <RightText
                  dangerouslySetInnerHTML={{
                    __html: "<span>-30%</span> sur le matelas",
                  }}
                />
              </TopSection>
            )}
            {data.title && (
              <TopBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              </TopBox>
            )}

            {data.text && (
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: data.text,
                }}
              />
            )}

            <TextWrapper>
              {data.text_list &&
                data.text_list.map((item, index) => {
                  return (
                    item &&
                    item.text && (
                      <TextBox key={index.toString()}>
                        <TickIcon
                          className="lazyload"
                          data-src="https://static.percko.com/uploads/b326f628-6c84-4c75-ba51-1a0d0ec7cc6a.png"
                        />
                        <Text
                          key={index.toString()}
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      </TextBox>
                    )
                  )
                })}
            </TextWrapper>
            {data.button_text && data.button_url && (
              <FlexWrapper>
                <StyledLink to={data.button_url}>{data.button_text}</StyledLink>
              </FlexWrapper>
            )}
          </ContentBox>
          {data.image && (
            <ImageWrapper>
              <Image
                className="lazyload"
                data-src={data.image}
                alt={data.alt ? data.alt : ""}
              />
            </ImageWrapper>
          )}
          {data.mobile_image && (
            <MobileImageWrapper>
              <Image
                className="lazyload"
                data-src={data.mobile_image}
                alt={data.alt ? data.alt : ""}
              />
            </MobileImageWrapper>
          )}
        </ShopContainer>
      </HomeMatelasSectionWrapper>
    )
  )
}

export default injectIntl(HomeMatelasSection)
